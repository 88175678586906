<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <h2 class="subtitle-1 text-uppercase grey--text">
          {{ $route.params.projectInfo.tenderName }}
        </h2>
      </v-col>
      <v-col cols="3">
        <h2 class="subtitle-1 grey--text">
          {{ $t('stage_label') }}: {{ $route.params.infoStage.name }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <ValidationObserver
      ref="obs"
      v-slot="{on, handleSubmit}"
    >
      <v-row no-gutters>
        <v-col cols="4">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="settingsValidations.nameStage"
            name="Nombre etapa"
            vid="encrypt"
          >
            <v-text-field
              id="fieldnameStage"
              v-model="$route.params.infoStage.name"
              :error-messages="errors"
              :label="$t('stage_name').toLowerCase()"
              type="text"
              outlined
              rounded
              v-on="on"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="2">
          <v-card-actions>
            <v-btn
              height="45px"
              color="primary"
              rounded
              block
              elevation="5"
              :disabled="!$route.params.infoStage.name"
              @click="handleSubmit(saveUpdateSection)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-row>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('sections_label') }}
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-3 gray-color" />
    <v-row
      justify="start"
    >
      <v-col cols="3">
        <v-btn
          large
          color="primary"
          rounded
          @click="() => { showModalSaveSections = true }"
        >
          {{ $t('add_stage') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
          color="quaternary"
        >
          <app-generic-table
            ref="genericTable"
            :table-fields="$_tableHeaderMixin_generate"
            :actions="true"
            :data-load="sectionsList.sectionResponseList"
            disable-pagination
            @edit-row="editProject"
            @delete-row="deleteSection"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end">
      <v-col cols="2">
        <v-btn
          color="gray_color lighten-1 white--text"
          class="mt-12 mr-4"
          block
          @click="toReturn"
        >
          {{ $t('exit') }}
        </v-btn>
      </v-col>
    </v-row>
    <sections-modal
      :show-modal="showModalSaveSections"
      title-modal="Crear seccion"
      label-field="Nombre sección"
      agree-text-bottom="Confirmar"
      disagree-text-bottom="Cancelar"
      @resolve="resolveSaveSections"
    />
  </v-container>
</template>

<script>
import tableHeaderMixin from '@/mixins/tableHeaderMixin'
import AppGenericTable from '@/components/uiComponents/genericTable/AppGenericTable'
import projectsSettingsSectionsHeader from './data/projectsSettingsSectionsHeader'
import settingsValidations from './data/settingsValidations'
import { GET_SECTIONS_DETAIL, GET_SECTIONS, UPDATE_NAME_STAGE } from './data/settingsSectionsConstant'
import { ADD_COUNTER_REQUEST, SHOW_ALERT, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import { CONFLICT } from '@/shared/constants/httpStatus'
import SectionsModal from './components/modal/ProjectSettingsSectionsModal'
import handleErrorMixin from '@/mixins/handleErrorMixin'

export default {
  name: 'ProjectsSettings',
  components: {
    AppGenericTable,
    SectionsModal
  },
  mixins: [tableHeaderMixin, handleErrorMixin],
  beforeRouteEnter (to, from, next) {
    if (to.params.infoStage && to.params.infoStage.id) {
      next()
    } else {
      next({ name: 'projects' })
    }
  },
  data () {
    return {
      settingsValidations,
      showModalSaveSections: false,
      columnOptions: projectsSettingsSectionsHeader,
      sectionsList: {}
    }
  },
  created () {
    this.getSections()
  },
  methods: {
    /**
     * This method add sections to project
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    addSectionsToProject (response) {
      let createSection = {
        stageId: this.$route.params.infoStage.id,
        name: response.nameSection,
        mandatoryDocument: response.mandatoryDocuments
      }
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.post(`${GET_SECTIONS}`, createSection
      ).then((response) => {
        this.$refs.genericTable.getDataFromApi()
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('save_successfully'),
          show: true
        })
      }).catch((error) => {
        if (error.response.status === CONFLICT) {
          this.$store.commit(SHOW_ALERT, {
            type: 'warning',
            text: this.$t('error'),
            show: true
          })
          return
        }
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.getSections()
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     * This method
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 09:30 AM
     */
    editProject (sectionInfo) {
      this.$router.push({ name: 'SettingsDocuments', params: { projectInfo: this.$route.params.projectInfo, sectionInfo: sectionInfo, infoStage: this.$route.params.infoStage } })
    },
    /**
     * This method get details of sections
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 09:30 AM
     */
    getSections () {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.get(`${GET_SECTIONS_DETAIL}`, { params: { stageId: this.$route.params.infoStage.id } }).then((response) => {
        this.sectionsList = response.data
      }).catch((error) => {
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     * This method call method to add section to project
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    resolveSaveSections (response) {
      this.showModalSaveSections = false
      if (response) {
        this.addSectionsToProject(response)
      }
    },
    /**
     * This method deleted a section from a project emit from AppTableGeneric
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 4:30 PM
     */
    deleteSection (response) {
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.delete(`${GET_SECTIONS}/${response.id}`)
        .then(obj => {
          this.$store.commit(SHOW_ALERT, {
            type: 'success',
            text: this.$t('delete_row'),
            show: true
          })
        })
        .catch(error => {
          this.$_handleErrorMixin_generateError(error)
        }).finally(() => {
          this.getSections()
          this.$store.commit(SUBTRACT_COUNTER_REQUEST)
        })
    },
    /**
     * This method update the name of sections
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    saveUpdateSection () {
      let dataUpdated = {
        name: this.$route.params.infoStage.name,
        mandatoryDocuments: null
      }
      this.$store.commit(ADD_COUNTER_REQUEST)
      this.axios.put(`${UPDATE_NAME_STAGE}/${this.$route.params.infoStage.id}`, dataUpdated).then((response) => {
        this.$store.commit(SHOW_ALERT, {
          type: 'success',
          text: this.$t('save_updated'),
          show: true
        })
      }).catch((error) => {
        this.$_handleErrorMixin_generateError(error)
      }).finally(() => {
        this.$store.commit(SUBTRACT_COUNTER_REQUEST)
      })
    },
    /**
     * This method return to previous page
     *
     * @author Andres Correa
     * @version 1.0.0
     * @since 21/07/2020 10:30 AM
     */
    toReturn () {
      this.$router.push({
        name: 'StagesConfiguration', params: { projectInfo: this.$route.params.projectInfo }
      })
    }
  }
}
</script>
<i18n src="./data/i18nMessage.json" />
