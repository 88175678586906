'use strict'

/**
 * Object column user table.
 *
 * @author Andres Correa
 * @since 22/07/2020 10:20 AM
 * @version 1.0.0
 */
export default [
  {
    text: 'column_table.name',
    align: 'left',
    sortable: true,
    value: 'name'
  },
  {
    text: 'column_table.mandatoryDocuments',
    align: 'center',
    sortable: true,
    value: 'mandatoryDocuments'
  }
]
