'use strict'

/**
 * Object with all validations of the legal area form inputs.
 *
 * @author Andres Correa
 * @since 26/07/2020 5:42 PM
 * @version 1.0.0
 */
export default {
  nameStage: {
    required: true,
    min: 8,
    max: 50,
    alpha: false
  }
}
