'use strict'

/**
 * Object with all validations of the legal area form inputs.
 *
 * @author Andres Correa
 * @since 12/05/2020 11:40 AM
 * @version 1.0.0
 */
export default {
  sectionName: {
    required: true,
    min: 8,
    max: 50,
    alpha: false
  },
  mandatory: {
    required: true,
    between: {
      min: 1,
      max: 50
    }
  }
}
